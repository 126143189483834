<template>
	<b-card no-body>
		<b-card-body class="text-center">
			<common-table-segmentation
				:show-details="false"
				title="Histórico de Segmentações"
				v-bind="configTableSegmentation"
				@update="handleUpdatedItems"
			>
				<template v-slot:filters>
					<div class="d-flex align-items-center">
						<div class="flex-shrink">
							<select
								v-model="configTableSegmentation.filters.order_column"
								class="form-control like-dropdown my-1"
							>
								<option :value="null" hidden selected>Filtrar por</option>
								<option value="name">Nome</option>
								<option value="created_at">Data de Criação</option>
							</select>
						</div>
						<div class="flex-shrink">
							<select
								v-model="configTableSegmentation.filters.order"
								class="form-control like-dropdown mx-md-2 my-1"
							>
								<option :value="null" hidden selected>Classificar por</option>
								<option value="asc">Ascendente</option>
								<option value="desc">Descendente</option>
							</select>
						</div>
						<div
							class="input-group input-group-merge search my-1 w-100 flex-grow-1"
							role="group"
							style="width: 300px !important"
						>
							<div class="input-group-prepend">
								<div class="input-group-text">
									<feather-icon icon="SearchIcon" size="25" />
								</div>
							</div>
							<input
								v-model="configTableSegmentation.filters.search"
								class="form-control"
								placeholder="Buscar segmentação"
								type="text"
							/>
						</div>
					</div>
				</template>
				<template v-slot:footer>
					<td class="text-left">Total</td>
					<td>
						{{ configTableSegmentation.misc ? configTableSegmentation.misc.total : 0 }}
					</td>
					<td :colspan="2" class="text-right">
						<b-link
							v-if="
								configTableSegmentation.misc &&
								configTableSegmentation.misc.current_page !==
									configTableSegmentation.misc.last_page
							"
							:to="{ name: '' }"
							@click="
								getSegmentations(`${configTableSegmentation.misc.current_page + 1}`)
							"
						>
							+ Mostrar mais
						</b-link>
					</td>
				</template>
			</common-table-segmentation>
		</b-card-body>
	</b-card>
</template>

<script>
import { BCard, BCardBody, BLink } from "bootstrap-vue";
import CommonTableSegmentation from "@/views/pages/dashboard/segmentation/commonTableSegmentation.vue";
import api from "@/api";

export default {
	components: {
		BCard,
		BCardBody,
		CommonTableSegmentation,
		BLink
	},
	data() {
		return {
			configTableSegmentation: {
				fields: [
					{
						key: "name",
						label: "Nome",
						sortable: false,
						sortDirection: "desc",
						class: "text-left"
					},
					{
						key: "shots",
						label: "Clientes",
						sortable: false
					},
					{
						key: "created_at",
						label: "Data de criação",
						sortable: false
					},
					{
						key: "actions",
						label: "",
						class: "text-right d-flex justify-content-around",
						sortable: false
					}
				],
				items: [],
				actions: [
					{ text: "Duplicar", link: "", roles: ["campaigns.create", "campaigns.update"] },
					{
						text: "Editar",
						link: "editar-segmentacao",
						roles: ["campaigns.create", "campaigns.update"]
					},
					{ text: "Deletar", link: "", roles: ["campaigns.delete"] },
					{ text: "Atualizar", link: "", roles: ["campaigns.update"] }
				].filter((e) => this.$role(e.roles)),
				filters: {
					order_column: null,
					order: null,
					search: null,
					limit: 5
				},
				timeout: null,
				isHeader: true,
				loading: false
			}
		};
	},

	created() {
		this.getSegmentations();
	},

	watch: {
		"configTableSegmentation.filters": {
			handler() {
				clearTimeout(this.configTableSegmentation.timeout);
				this.configTableSegmentation.timeout = setTimeout(this.getSegmentations, 500);
			},
			deep: true
		}
	},

	methods: {
		handleUpdatedItems(data) {
			this.configTableSegmentation.items = data.body;
			this.configTableSegmentation.misc = data.misc ?? this.configTableSegmentation.misc;
			this.configTableSegmentation.loading = data.loading ?? false;
		},

		getSegmentations(page = 1) {
			this.configTableSegmentation.loading = true;
			const params = { page, ...this.configTableSegmentation.filters };

			api.get("/segmentation/", { params })
				.then((response) => {
					const { type, body, misc } = response.data;
					if (type !== "success") return;
					if (page > 1) this.configTableSegmentation.items.push(...body);
					else this.configTableSegmentation.items = body;

					this.configTableSegmentation.misc = misc;
				})
				.finally(() => {
					this.configTableSegmentation.loading = false;
				});
		}
	}
};
</script>

<style></style>

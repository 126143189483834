<template>
    <section id="dashboard-register">
        <b-row class="title mb-2" v-if="$role(['campaigns.create', 'campaigns.update'])">
            <b-col class="text-start">
                <card-header v-bind="cardHeaderOptions" />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <register-table />
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import CardHeader from "@/views/components/CardHeader.vue";
import RegisterTable from "./SegmentationTable.vue";

export default {
    components: {
        BRow,
        BCol,
        RegisterTable,
        CardHeader,
    },
    data() {
        return {
            data: {},
            cardHeaderOptions: {
                title: "Segmentações",
                description:
                    "Agende de forma rápida e fácil suas campanhas, utilizando configurações já estabelecidas ou criando novas.",
                icon: require("@/assets/images/icons/filter-and-sort.svg"),
                link: "nova-segmentacao",
                textButton: "Criar Segmentação",
            },
        };
    },  
    created() {},
    methods: {},
};
</script>
